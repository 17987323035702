import 'slick-carousel';
import 'jquery-match-height';

export default class Carousel {
  constructor(userOptions = {}, slickOptions = {}) {
    var options = Object.assign({
      selector: '.slick-carousel',
      slickOptions: Object.assign({
        autoPlay: false,
      }, slickOptions)
    }, userOptions);
    $(options.selector).slick(options.slickOptions);
    // Hero banner slider
    $('.bj-block-hero-banner-slider').slick({
      arrows: false,
      autoplay: true
    });
    // bj menu slider block
    $('.bj-module-menu-card__slider').on('init breakpoint', function (event) {
        $('.bj-module-menu-card__slider article').matchHeight();
    });
    $('.bj-module-menu-card__slider').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }
        ]
    });
  }
}
